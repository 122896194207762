import { FeatureData } from '@innedit/innedit';
import { FeatureType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../../../../components/View';
import Form from '../../../../../../../containers/Espace/Form';
import comment from '../../../../../../../params/contact/comment.json';
import TemplateEspace from '../../../../../../../templates/espace';
import requireEspace, {
  EspaceProps,
} from '../../../../../../../utils/requireEspace';
import { UserProps } from '../../../../../../../utils/requireUser';

const PageEspaceProfilComment: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  user,
  params: { espaceId, profilId },
}) => (
  <TemplateEspace espace={espace} user={user}>
    <CMSView>
      <Form
        docId={profilId}
        itemPathnamePrefix={`/espaces/${espaceId}/profiles/${profilId}/comments/`}
        model={
          new FeatureData<FeatureType>({
            espaceId,
            collectionName: 'comments',
            params: comment,
            parentCollectionName: 'profiles',
            parentId: profilId,
          })
        }
        name="comment"
        type="update"
      />
    </CMSView>
  </TemplateEspace>
);

export default requireEspace(PageEspaceProfilComment);
